(function() {
  "use strict";

  // Lateral direita fixa ao dar scroll na página
  const element = $(".servico-detalhe-foto");
  const container = $(".servico-detalhe-foto-container");
  const leftContent = $(".servico-detalhe-texto");

  if (element.length > 0 && !isMobileX(800)) {
    const marginTop = $(".header-full").outerHeight() + 40;
    console.log(marginTop);
    const elementHeight = element.outerHeight();
    const elementWidth = element.width();
    const leftContentHeight = leftContent.outerHeight();
    const leftContentOffsetTop = leftContent.offset().top;
    const leftContentBottom = leftContentHeight + leftContentOffsetTop;
    const elementOffsetTop = element.offset().top;
    const elementOffsetLeft = element.offset().left;
    const windowHeight = $(window).height();

    element.css("max-width", elementWidth);

    if (leftContentHeight > elementHeight) {
      container.height(leftContentHeight);
    }

    $(window).on("scroll", function() {
      const scrollTop = $(window).scrollTop();
      if (scrollTop + marginTop > elementOffsetTop) {
        element.css({
          position: "fixed",
          top: marginTop,
          left: elementOffsetLeft,
          bottom: ""
        });
        if (scrollTop + windowHeight > leftContentBottom) {
          element.css({
            position: "absolute",
            top: "",
            left: "",
            bottom: 0
          });
        }
      } else {
        element.css({
          position: "relative",
          top: "",
          left: "",
          bottom: ""
        });
      }
    });
  }
})();
