/* =================== DEPOIMENTOS =================== */
jQuery(document).ready(function($){
  $('.carrosel-depoimentos').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true
  });
});
